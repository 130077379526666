class NotificationsService {

    /**
     * Fetch notification config from content server
     * If startWsConnection is passed (true by default) it will start the websockets connection present in the notification config
     *
     * @param {boolean} startWsConnection - if true will start websockets present on the config
     */
    initNotifications(startWsConnection = true) {
        this.notificationsProvider.initNotifications(startWsConnection);
    }

    /*========================================================= Websockets event handler ================================================*/

    /**
     * Method to explicitly close a source of notifications.
     * The source of notifications may be a ws or http connection.
     * If the source is an ws connection it will close the socket, otherwise it will only stop making get requests.
     * @param {String} key - Key that identifies the external source
     * @param {Function} callback - Callback functions to call once the close method is successfull
     */
    close(key, callback) {
        this.notificationsProvider.close(key, callback);
    }

    /*========================================================= Http connection handler ================================================*/

    /**
     *This method will retrieve all the notification categories and set them in the reducer.
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    getCategories = () => {
        return this.notificationsProvider.getCategories();
    };

    /**
     * Method that retrieves:
     * - The total number o notifications
     * - The total number of unread notification
     * From all the categories and sets it in the store.
     *
     * It can be filtered by the following keys:
     *
     * All the following args should be inside an object passed to this function
     * @param {String} filter.keyword - Keywords text to filter the notifications
     * @param {Date} filter.beginDate - Notification should be created after this date
     * @param {Date} filter.endDate - Notification should be created before this date
     * @param {string} filter.status - if notifications are read
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    getMetadata(filter, resetLoading) {
        return this.notificationsProvider.getMetadata(filter, resetLoading);
    }

    /**
     * Method to retrieve notifications from the http connection
     * It is possible to send filters to the search.
     *
     * Save a map entry to the reducer where the request is done.
     *
     * All the following args should be inside an object passed to this function
     * @param {object} filter - Identifier of category to filter
     * @param {String} filter.categoryId - Identifier of category to filter
     * @param {String} filter.keywords - Keywords text to filter the notifications
     * @param {Date} filter.startDate - Start date of the notifications
     * @param {Date} filter.endDate - End date of the notifications
     * @param {Boolean} filter.isRead - Filter the notifications based on the "read" value (if the notification is already read or not)
     * @param {number} filter.page - page wanted and number of records for each page
     * @param {number} filter.itemsPerPage - page wanted and number of records for each page
     * @param {boolean} userNotifications - sets data to notification var
     * @param {boolean} useCenterNotifications - sets data to centerNotifications var
     * @param {boolean} resetData - sets instead of adding and sets loadContent to false
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    get(filter = {}, userNotifications = false, useCenterNotifications = false, resetData = false) {
        return this.notificationsProvider.get(filter, userNotifications, useCenterNotifications, resetData);
    }

    /**
     * Method to set a notification as read.
     * @param {*} id - Identifier of the notification
     */
    markRead(id) {
        this.notificationsProvider.markRead(id);
    }

    /**
     * Method to set all notifications as read.
     * If a category is sent as argument, it will only mark the notifications of that category as read.
     * @param {*} category - Identifier of the category filter
     */
    markAllRead(category) {
        this.notificationsProvider.markAllRead(category);
    }

    /**
     * Method to set a notification as unread.
     * @param {*} id - Identifier of the notification
     */
    markUnread(id) {
        this.notificationsProvider.markUnread(id);
    }

    /**
     * Method to delete a notification
     * @param {*} id - Identifier of the notification
     */
    delete(id) {
        this.notificationsProvider.delete(id);
    }

    /**
     * Method to delete all notifications.
     * If a category is sent as argument, it will only delete the notifications of that category.
     * @param {*} category - Identifier of the category filter
     */
    deleteAll(category) {
        this.notificationsProvider.deleteAll(category);
    }

    /* ================================================================== Setters ==================================================================*/

    /**
     * Set the provider in the notifications service
     * @param {Object} notificationsProvider - Notifications object containing all the implementations
     */
    setNotificationsProvider(notificationsProvider) {
        this.notificationsProvider = notificationsProvider;
    }

    /**
     * Method to set the httpclient used to retrieve the notifications from external sources that consist in http requests
     * @param {Object} httpClient - Http Client
     */
    setHttpClient(httpClient) {
        this.notificationsProvider.setHttpClient(httpClient);
    }

    /**
     * Method to set the RealTime client used to retrieve the notification from external source that consist in ws connections
     * @param {Object} realTimeClient - Real Time Client
     */
    setRealTimeClient(realTimeClient) {
        this.notificationsProvider.setRealTimeClient(realTimeClient);
    }

    /**
     * Method to set the project store
     * @param {Object} store - Project store
     */
    setStore(store) {
        this.notificationsProvider.setStore(store);
    }

}

const notificationService = new NotificationsService();
export default notificationService;
  