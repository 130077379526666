import { REMOVE_NOTIFICATION, ADD_NOTIFICATION } from "../actions/ContextualNotifications";

const initialState = {
    /**
     * List of contextual notification
     * Shape:
     * {
     *     key : number,
     *     message: String,
     *     timeout: number (time in ms)
     *     severity: One of ("info", "success", "error", "warning")
     * }
     */
    contextualNotificationList: [],
};

export default function reducerOne(state, action) {

    if (state === undefined)
        return initialState;

    switch (action.type) {
        case ADD_NOTIFICATION:
            if (!action.notification || !action.notification.key) {
                console.error("Contextual notification malformed");
                return state
            }
            return {...state, contextualNotificationList: state.contextualNotificationList.concat(action.notification)};
        case REMOVE_NOTIFICATION:
            if (!action.notificationKey) {
                console.error("No contextual notification passed")
            }
            return {...state, contextualNotificationList: state.contextualNotificationList.filter(e => e.key !== action.notificationKey)};
        default:
            return state;
    }
}
