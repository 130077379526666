import {
    SET_NOTIFICATION_CENTER_OPEN, SET_CATEGORIES, SET_METADATA, ADD_CENTER_NOTIFICATIONS, SET_NOTIFICATION_QUICK_ACTION_OPEN, SET_NOTIFICATION_CENTER_FILTER_OPEN,
    CLEAR_FILTERS, SET_NOTIFICATION_BADGE_CONTENT, SET_END_DATE_FILTER, SET_KEYWORDS_FILTER, SET_START_DATE_FILTER, SET_READ_FILTER,
    SET_SELECTED_CATEGORY, SET_PRE_FETCH_STATUS, SET_SELECTED_NOTIFICATION, ADD_NOTIFICATIONS, REMOVE_NOTIFICATION_SNACKBAR,
    SET_NOTIFICATIONS_PAGE, SET_CENTER_NOTIFICATIONS_PAGE, SET_CONFIG, SET_IS_LOADING_METADATA, SET_IS_LOADING_DATA, SET_CENTER_NOTIFICATIONS, SET_NOTIFICATIONS, SET_LIST_METADATA, SET_MODAL
} from '../actions/NonContextualNotifications';

const defaultFilters = {
    keywords : undefined,
    startDate : undefined,
    endDate : undefined,
    isReadFilter : undefined
};

const initialState = {
    //config
    config : undefined,
    //Notification data
    preFetchDone : false, //If true initial fetch is done
    preFetchError : false,
    categories : [],
    metadata : [],
    listMetadata : [],
    notifications : [],
    centerNotifications : [],
    notificationSnackbar : [],
    //UI
    isNotificationCenterOpen : false,
    isNotificationCenterFiltersOpen : false,
    isNotificationQuickActionOpen : false,
    notificationBadgeContent : [],
    selectedCategory : 'total',
    selectedNotification : undefined,
    notificationsPage : 1,
    centerNotificationsPage : 1,
    isLoadingData : false,
    isLoadingMetadata : false,
    isModalOpen : false,
    modalEvent : undefined,
    modalCallback : undefined,
    //Filters
    ...defaultFilters
};

export default function reducerOne(state, action) {

    if (state === undefined)
        return initialState;

    switch ( action.type ) {
        case SET_CONFIG:
            return { ...state, config : action.config };
        /* ================================ NOTIFICATION - Logic ============================================*/
        case SET_CATEGORIES:
            return { ...state, categories : action.categories };
        case SET_METADATA:
            if (action.stopLoading) {
                return { ...state, metadata : action.metadata, isLoadingMetadata : false };
            }
            return { ...state, metadata : action.metadata };
        case ADD_NOTIFICATIONS:
            let notificationSnackbar = [];
            if (action.showSnackbar) {
                notificationSnackbar.push(action.notification);
            }
            if (Array.isArray(action.notification) && action.prepend)
                return { ...state, notifications : [...action.notification, ...state.notifications], notificationSnackbar : state.notificationSnackbar.concat(notificationSnackbar) };
            if (Array.isArray(action.notification))
                return { ...state, notifications : state.notifications.concat(action.notification), notificationSnackbar : state.notificationSnackbar.concat(notificationSnackbar) };
            return { ...state, notifications : [action.notification, ...state.notifications], notificationSnackbar : state.notificationSnackbar.concat(notificationSnackbar) };
        case ADD_CENTER_NOTIFICATIONS:
            if (Array.isArray(action.notification) && action.prepend)
                return { ...state, centerNotifications : [...action.notification, ...state.centerNotifications] };
            if (Array.isArray(action.notification))
                return { ...state, centerNotifications : state.centerNotifications.concat(action.notification) };
            return { ...state, centerNotifications : [action.notification, ...state.centerNotifications] };
        case SET_CENTER_NOTIFICATIONS:
            if (action.stopLoading) {
                return { ...state, centerNotifications : action.notifications, isLoadingData : false };
            }
            return { ...state, centerNotifications : action.notifications };
        case SET_NOTIFICATIONS:
            if (action.stopLoading) {
                return { ...state, notifications : action.notifications, isLoadingData : false };
            }
            return { ...state, notifications : action.notifications };
        case REMOVE_NOTIFICATION_SNACKBAR:
            if (action.notificationId) {
                return { ...state, notificationSnackbar : state.notificationSnackbar.filter(e => e.id !== action.notificationId) };
            }
            return { ...state, notificationSnackbar : [] };
        case SET_PRE_FETCH_STATUS:
            return { ...state, preFetchDone : action.done, preFetchError : action.error };
        case SET_LIST_METADATA:
            return { ...state, listMetadata : action.metadata };
        /* ======================================================= Notifications ui ===============================================*/
        case SET_NOTIFICATION_CENTER_OPEN:
            return { ...state, isNotificationCenterOpen : action.isOpen };
        case SET_NOTIFICATION_QUICK_ACTION_OPEN:
            return { ...state, isNotificationQuickActionOpen : action.isOpen };
        case SET_NOTIFICATION_CENTER_FILTER_OPEN:
            return { ...state, isNotificationCenterFiltersOpen : action.isOpen };
        case SET_NOTIFICATION_BADGE_CONTENT:
            return { ...state, notificationBadgeContent : action.content };
        case SET_SELECTED_CATEGORY:
            return { ...state, selectedCategory : action.categoryId };
        case SET_SELECTED_NOTIFICATION:
            if (action.isNotificationCenterOpen === undefined) {
                return { ...state, selectedNotification : action.selectedNotification };
            } else {
                return {
                    ...state,
                    selectedNotification : action.selectedNotification,
                    isNotificationCenterOpen : action.isNotificationCenterOpen,
                    isNotificationQuickActionOpen : !action.isNotificationCenterOpen
                };
            }
        case SET_NOTIFICATIONS_PAGE:
            return { ...state, notificationsPage : action.page };
        case SET_CENTER_NOTIFICATIONS_PAGE:
            return { ...state, centerNotificationsPage : action.page };
        case SET_IS_LOADING_DATA:
            return { ...state, isLoadingData : action.value };
        case SET_IS_LOADING_METADATA:
            return { ...state, isLoadingMetadata : action.value };
        case SET_MODAL:
            return { ...state, isModalOpen : action.isOpen, modalEvent : action.modalEvent, modalCallback : action.modalCallback };
        /* ======================================================= Filters ===============================================*/
        case SET_KEYWORDS_FILTER:
            return { ...state, keywords : action.keywords };
        case SET_READ_FILTER:
            return { ...state, isReadFilter : action.readFilter };
        case SET_START_DATE_FILTER:
            return { ...state, startDate : action.startDate };
        case SET_END_DATE_FILTER:
            return { ...state, endDate : action.endDate };
        case CLEAR_FILTERS:
            return { ...state, ...defaultFilters, selectedCategory : 'total' };
        default:
            return state;
    }
}
